angular.module('aerosApp')
    .factory('httpInterceptor', httpInterceptor)
    .config([ "$httpProvider", function($httpProvider) {
        $httpProvider.interceptors.push('httpInterceptor');
    }]);

httpInterceptor.$inject = ['$injector', '$q'];

function httpInterceptor($injector, $q) {


    return {
        request: function(config) {
            var SessionService = $injector.get('SessionService');

            var sessionInfo = SessionService.getSessionInfo();

            // TODO: Should we check sessionInfo.tokenExpires here before making a call?
            if (sessionInfo && sessionInfo.token) {
                config.headers['X-User-AerosAuth'] = sessionInfo.token;
            }

            return config;
        },

        requestError: function(config) {
            return config;
        },

        response: function(res) {
            var tokenExpires = res.headers('X-User-AerosAuthExpires');
            if (tokenExpires) {
                $injector.get('SessionService').updateSessionExpires(tokenExpires);
            }
            return res;
        },

        responseError: function(res) {
            if (res.status === 401) {
                var LOGIN = $injector.get('routesConstant').LOGIN;
                $injector.get('$state').go(LOGIN.LOGOUT.stateName);
            }

            return $q.reject(res);
        }
    }
}
